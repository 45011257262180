//Containers

.spartan-wrapper{
	height: 100%;
}

//Brand

.spartan-brand{
	max-width: 500px;
	width: 100%;
	
	.spartan-emblem{
		width: 100%;
		max-width: 30%;
		margin: 0 0 1.25rem;
	}
	
	.spartan-name{
		width: 100%;
		max-width: 500px;
		margin: 0 0 4rem;
	}
	
	p{
		color: $black;
		animation-delay: 1.5s;
	}
	
	a:hover{
		color: $red;
		text-decoration: none;
	}
	
	.hvr-underline-from-left{
		vertical-align: top;
	}
	
	.hvr-underline-from-left:before{
		height: 1px;
		background: $red;
	}
}