//General

html{
	width: 100%;
	height: 100%;
}

.home{
	width: 100%;
	height: 100%;
	background: url('../images/spartan-bg.jpg');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: $white;
	font-family: 'Montserrat', sans-serif;
	padding: 0 36px 36px;
}

img{
	width: 100%;
	height: auto;
	display: block;
}

a{
	color: $red;
}
