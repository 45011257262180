.spartan-footer{
	line-height: 1.5;
	text-align: center;
	font-size: 0.75rem;
	animation-delay: 2s;
	
	ul{
		list-style: none;
		padding: 0;
		color: $black;
	}
	
	ul:last-child{
		margin: 0;
	}
	
	strong{
		text-transform: uppercase;
	}
	
	span{
		display: block;
	}
	
	a:hover{
		color: $red;
		text-decoration: none;
	}
	
	.hvr-underline-from-left{
		vertical-align: top;
	}
	
	.hvr-underline-from-left:before{
		height: 1px;
		background: $red;
	}
}